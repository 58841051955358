import { render, staticRenderFns } from "./proces-verbaal-edit.vue?vue&type=template&id=27cdda80"
import script from "./proces-verbaal-edit.ts?vue&type=script&lang=ts&external"
export * from "./proces-verbaal-edit.ts?vue&type=script&lang=ts&external"
import style0 from "./proces-verbaal-edit.vue?vue&type=style&index=0&id=27cdda80&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports