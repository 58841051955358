import { defineComponent, PropType } from 'vue'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import { Inbreuk, Misdrijf, StedenbouwkundigeOvertredingInlichting, } from '@/infrastructure/bff-client/bff-client'
import HerstelmaatregelPreview
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/preview/handhavingsinstrumenten/herstelmaatregel-preview.vue'
import ProcesVerbaalPreview
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/preview/handhavingsinstrumenten/proces-verbaal-preview.vue'
import StakingsbevelPreview
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/preview/handhavingsinstrumenten/stakingsbevel-preview.vue'
import AanmaningPreview
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/preview/handhavingsinstrumenten/aanmaning-preview.vue'
import BestuurlijkeSanctiePreview
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/preview/handhavingsinstrumenten/bestuurlijke-sanctie-preview.vue'
import MinnelijkeSchikkingPreview
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/preview/handhavingsinstrumenten/minnelijke-schikking-preview.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import useInlichtingPreview from '@/components/vip-inlichting/new-version/composables/inlichting-preview.composable'

export default defineComponent({
  name: 'stedenbouwkundige-overtreding-preview',
  components: {
    HerstelmaatregelPreview,
    ProcesVerbaalPreview,
    StakingsbevelPreview,
    AanmaningPreview,
    BestuurlijkeSanctiePreview,
    MinnelijkeSchikkingPreview
  },
  props: {
    inlichting: Object as PropType<StedenbouwkundigeOvertredingInlichting>
  },
  setup (props) {
    const { vipInlichting, downloadUrl } = useInlichtingPreview(props.inlichting)
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()
    const gerelateerdeWetgevingLabel = (key: string) => options.get('gerelateerdeWetgeving').get(key)
    const inlichtingTitle = 'Stedenbouwkundige overtreding'

    const isMisdrijfZonderInstrumenten = (misdrijf: Misdrijf): boolean => {
      const geenAanmaningen = (misdrijf.aanmaningen?.length ?? 0) === 0
      const geenHerstelmaatregelen = (misdrijf.bestuurlijkeHerstelmaatregelen?.length ?? 0) === 0
      const geenStakingsbevel = misdrijf.stakingsbevel == null
      const geenMinnelijkeSchikking = misdrijf.minnelijkeSchikking == null
      const geenProcesVerbaal = (misdrijf.processenVerbaal?.length ?? 0) === 0
      return geenAanmaningen && geenHerstelmaatregelen && geenStakingsbevel && geenProcesVerbaal && geenMinnelijkeSchikking
    }

    const isInbreukZonderInstrumenten = (inbreuk: Inbreuk): boolean => {
      const geenAanmaningen = (inbreuk.aanmaningen?.length ?? 0) === 0
      const geenHerstelmaatregelen = (inbreuk.bestuurlijkeHerstelmaatregelen?.length ?? 0) === 0
      const geenStakingsbevel = inbreuk.stakingsbevel == null
      const geenMinnelijkeSchikking = inbreuk.minnelijkeSchikking == null
      const geenProcesVerbaal = inbreuk.bestuurlijkeSanctie == null
      return geenAanmaningen && geenHerstelmaatregelen && geenStakingsbevel && geenProcesVerbaal && geenMinnelijkeSchikking
    }

    return {
      vipInlichting,
      options,
      inlichtingTitle,
      getLabel,
      gerelateerdeWetgevingLabel,
      formatDate,
      formatNumber,
      downloadUrl,
      isMisdrijfZonderInstrumenten,
      isInbreukZonderInstrumenten
    }
  }
})
