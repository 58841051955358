import { defineComponent, PropType, ref } from 'vue'
import {
  RechtbankVanEersteAanlegTypeRechtbank,
  UitsprakenRechterlijkeInstantie
} from '@/infrastructure/bff-client/bff-client'
import useHandhavingsinstrument
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/composable/handhavingsinstrument.composable'
import CollectionItem
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/components/collection-item/collection-item.vue'
import PvUitspraakEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/proces-verbaal-uitspraken/pv-uitspraak-edit.vue'
import {
  RechterlijkeInstantie,
  UitspraakRechterlijkeInstantie
} from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/proces-verbaal-edit'
import EventBus from '@/infrastructure/events/event-bus'

export default defineComponent({
  name: 'pv-uitspraken-edit',
  components: { CollectionItem, PvUitspraakEdit },
  props: {
    uitspraken: Array as PropType<UitspraakRechterlijkeInstantie[]>,
    procedureIndex: Number,
    instrumentIndex: Number,
    validationErrors: Map<string, string>,
    modDisabled: Boolean
  },
  setup (props, { emit }) {
    const { makeUnique } = useHandhavingsinstrument()
    const uitsprakenEdit = ref<UitspraakRechterlijkeInstantie[]>(props.uitspraken)
    const uitspraakToRemove = ref(null)

    const addUitspraak = () => {
      uitsprakenEdit.value.push({ adviesHogeRaadVoorHandhavingsuitvoering: {}, rechtbankEersteAanleg: {}, hofVanBeroep: {}, hofVanCassatie: {} } as UitspraakRechterlijkeInstantie)
      updateUitspraken()
    }

    const requestRemoveUitspraak = (index: number) => {
      uitspraakToRemove.value = index
    }

    const removeUitspraak = () => {
      uitsprakenEdit.value.splice(uitspraakToRemove.value, 1)
      cancelRemove()
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
      updateUitspraken()
    }

    const cancelRemove = () => {
      uitspraakToRemove.value = null
    }

    const handleUitspraakChanged = (uitspraakIndex: number, uitspraak: any) => {
      console.log('handle-changed', uitspraak)
      uitsprakenEdit.value[uitspraakIndex] = structuredClone(uitspraak)
      updateUitspraken()
    }

    const updateUitspraken = () => {
      const uitspraken = {
        adviesHogeRaadVoorHandhavingsuitvoering: null,
        rechtbankenVanEersteAanleg: [],
        hovenVanBeroep: [],
        hofVanCassatieUitspraken: []
      } as UitsprakenRechterlijkeInstantie
      console.log('update-uitspraken', uitsprakenEdit.value, uitspraken)
      uitsprakenEdit.value.forEach(uitspraak => {
        switch (uitspraak.rechtbank) {
          case RechterlijkeInstantie.HogeRaadVoorHandhavingsuitvoering: {
            uitspraken.adviesHogeRaadVoorHandhavingsuitvoering = {
              datum: uitspraak.adviesHogeRaadVoorHandhavingsuitvoering.datum,
              soortAdvies: uitspraak.adviesHogeRaadVoorHandhavingsuitvoering.soortAdvies
            }
            break
          }
          case RechterlijkeInstantie.RechtbankEersteAanlegBurgerlijk:
          case RechterlijkeInstantie.RechtbankEersteAanlegCorrectioneel: {
            uitspraken.rechtbankenVanEersteAanleg.push({
              typeRechtbank: uitspraak.rechtbank === RechterlijkeInstantie.RechtbankEersteAanlegBurgerlijk
                ? RechtbankVanEersteAanlegTypeRechtbank.BURGERLIJK
                : RechtbankVanEersteAanlegTypeRechtbank.CORRECTIONEEL,
              datumVonnis: uitspraak.rechtbankEersteAanleg.datumVonnis,
              omschrijving: uitspraak.rechtbankEersteAanleg.omschrijving,
              datumBetekeningVonnis: uitspraak.rechtbankEersteAanleg.datumBetekeningVonnis
            })
            break
          }
          case RechterlijkeInstantie.HofVanBeroep: {
            uitspraken.hovenVanBeroep.push({
              datumBeroepIngediend: uitspraak.hofVanBeroep.datumBeroepIngediend,
              beroepsarrest: uitspraak.hofVanBeroep.beroepsarrest,
              omschrijvingBeroepsarrest: uitspraak.hofVanBeroep.omschrijvingBeroepsarrest,
              datumDadingUitvoeringstermijnHerstelmaatregel: uitspraak.hofVanBeroep.datumDadingUitvoeringstermijnHerstelmaatregel,
              datumBetekeningArrest: uitspraak.hofVanBeroep.datumBetekeningArrest
            })
            break
          }
          case RechterlijkeInstantie.HofVanCassatie: {
            uitspraken.hofVanCassatieUitspraken.push({
              datumBeroepIngediend: uitspraak.hofVanCassatie.datumBeroepIngediend,
              beroepsarrest: uitspraak.hofVanCassatie.beroepsarrest,
              omschrijvingBeroepsarrest: uitspraak.hofVanCassatie.omschrijvingBeroepsarrest,
              datumDadingUitvoeringstermijnHerstelmaatregel: uitspraak.hofVanCassatie.datumDadingUitvoeringstermijnHerstelmaatregel,
              datumBetekeningArrest: uitspraak.hofVanCassatie.datumBetekeningArrest
            })
            break
          }
        }
      })
      emit('uitspraken-changed', uitspraken)
    }

    return {
      uitsprakenEdit,
      uitspraakToRemove,
      makeUnique,
      addUitspraak,
      requestRemoveUitspraak,
      removeUitspraak,
      cancelRemove,
      handleUitspraakChanged
    }
  }
})
