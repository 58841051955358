import { readonly } from 'vue'
import { Inlichting } from '@/infrastructure/bff-client/bff-client'

export default function useInlichtingPreview (inlichting: Inlichting) {
  const vipInlichting = readonly(inlichting)
  const downloadUrl = (bijlageId: string): string => {
    return config.VUE_APP_BASE_URI + '/bff/dossiers/bijlagen/' + bijlageId
  }
  return {
    vipInlichting,
    downloadUrl
  }
}
