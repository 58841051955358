import { defineComponent, PropType } from 'vue'
import { ProcesVerbaal } from '@/infrastructure/bff-client/bff-client'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import { formatDate } from '@/infrastructure/filters/filters'

export default defineComponent({
  name: 'proces-verbaal-preview',
  props: {
    previewData: Object as PropType<ProcesVerbaal>
  },
  setup (props) {
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()
    const preview = props.previewData as ProcesVerbaal
    return { options, preview, getLabel, formatDate }
  }
})
