import {
  Inlichting,
  InlichtingenIndicator,
  InlichtingInput, InlichtingInputInlichtingenIndicator
} from '@/infrastructure/bff-client/bff-client'
import { computed, getCurrentInstance, onMounted, reactive, Ref, ref } from 'vue'
import { Aangevraagd } from '@/infrastructure/constants/dashboard-tabs'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { useDossierStore } from '@/infrastructure/stores/dossier-store'
import VipInlichtingAccordion
  from '@/components/vip-inlichting/components/vip-inlichting-accordion/vip-inlichting-accordion'
import { generateKey } from '@/infrastructure/helpers/general'

export default function useEditableInlichting (
  inlichting: Inlichting,
  mapInlichtingToInput: (vipInlichting?: Inlichting)=> InlichtingInput,
  mapInputToInlichting: (input: InlichtingInput, inlichtingId: string)=> Inlichting) {
  const refs: { [key: string]: Ref } = reactive({})
  const vipInlichting = ref(inlichting)
  const inlichtingInput = ref(mapInlichtingToInput(inlichting))
  const addedBijlagen = ref([] as File[])
  const inlichtingBijlagen = ref(inlichting.bijlagen)
  const saving = ref(false)

  const validationErrors = ref(new Map<string, string>())
  const accordion = ref<InstanceType<typeof VipInlichtingAccordion>>(null)

  const route = getCurrentInstance()?.proxy?.$route

  const userStore = useUserStore()
  const dossierStore = useDossierStore()

  const isDossierEditable = computed(() => !dossierStore.dossierIsReadOnly && route?.params?.dossierType !== Aangevraagd)
  const canEdit = computed(() => isDossierEditable.value && (userStore.userCanEditDossier || userStore.userIsAdmin))

  const generateInlichtingKey = () => `${(inlichting as any).inlichtingType?.toString().toLowerCase()}-${generateKey()}`
  const inlichtingKey = ref(generateInlichtingKey())

  const bijlageUploaded = (bijlagen: File[]) => {
    addedBijlagen.value.push(...bijlagen)
  }

  const inlichtingToggled = (isOpen: boolean) => {
    if (isOpen) {
      if (vipInlichting.value.inlichtingenIndicator === InlichtingenIndicator.JA) {
        const element = refs[inlichting.inlichtingId].value
        scrollInlichtingIntoView(element)
      } else {
        window.scrollBy({ top: 200 })
      }
    }
  }

  const toggleIndicator = (vanToepassing: boolean) => {
    inlichtingInput.value.inlichtingenIndicator = vanToepassing ? InlichtingInputInlichtingenIndicator.JA : InlichtingInputInlichtingenIndicator.NEE
  }

  const cancelEdit = () => {
    inlichtingInput.value = mapInlichtingToInput(vipInlichting.value)
    accordion.value.opened = false
    scrollInlichtingIntoView(accordion.value)
    validationErrors.value.clear()
  }

  const saveInlichting = (inlichtingId: string) => {
    vipInlichting.value = mapInputToInlichting(inlichtingInput.value, inlichtingId)
    inlichtingKey.value = generateInlichtingKey()
    accordion.value.opened = false
    scrollInlichtingIntoView(accordion.value)
    validationErrors.value.clear()
  }

  const scrollInlichtingIntoView = (element: any) => {
    if (element) {
      setTimeout(() => {
        element.$el.scrollIntoView({ behavior: 'smooth' })
        window.scrollBy({ top: -160 })
      }, 100)
    }
  }

  onMounted(() => {
    if (!inlichting) { return }
    refs[inlichting.inlichtingId] = ref(null)
  })

  return {
    refs,
    accordion,
    vipInlichting,
    inlichtingInput,
    addedBijlagen,
    validationErrors,
    saving,
    inlichtingKey,
    canEdit,
    inlichtingBijlagen,
    bijlageUploaded,
    toggleIndicator,
    inlichtingToggled,
    cancelEdit,
    saveInlichting
  }
}
