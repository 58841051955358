import { defineComponent, PropType, ref, watch } from 'vue'
import { MinnelijkeSchikking } from '@/infrastructure/bff-client/bff-client'
import useHandhavingsinstrument
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/composable/handhavingsinstrument.composable'
import useInlichtingValidation
  from '@/components/vip-inlichting/new-version/composables/inlichting-validation.composable'

export default defineComponent({
  name: 'minnelijke-schikking-edit',
  props: {
    instrument: Object as PropType<MinnelijkeSchikking>,
    procedureIndex: Number,
    instrumentIndex: Number,
    modDisabled: Boolean,
    validationErrors: Map<string, string>
  },
  setup (props, { emit }) {
    const { makeUnique } = useHandhavingsinstrument()
    const { hasError } = useInlichtingValidation(props.validationErrors)

    const minnelijkeSchikkingEdit = ref(props.instrument as MinnelijkeSchikking)

    watch(() => minnelijkeSchikkingEdit.value, (newValue) => {
      emit('instrument-changed', { index: props.instrumentIndex, value: newValue })
    })

    return {
      minnelijkeSchikkingEdit,
      hasError,
      makeUnique
    }
  }
})
