import { computed, defineComponent, PropType, ref, watch } from 'vue'
import {
  AdviesHogeRaadVoorHandhavingsuitvoering,
  HofVanBeroep,
  ProcesVerbaal,
  ProcesVerbaalBeslissingParket,
  RechtbankVanEersteAanleg,
  RechtbankVanEersteAanlegTypeRechtbank, RestHofVanCassatieUitspraak,
  Seponering,
  UitsprakenRechterlijkeInstantie, Vervolging
} from '@/infrastructure/bff-client/bff-client'
import useHandhavingsinstrument
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/composable/handhavingsinstrument.composable'
import CollectionItem
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/components/collection-item/collection-item'
import PvUitsprakenEdit
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/edit/procedure/handhavingsinstrumenten/proces-verbaal-uitspraken/pv-uitspraken-edit.vue'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import { HandhavingsinstrumentType } from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/edit/procedure/procedure-edit'
import useInlichtingValidation
  from '@/components/vip-inlichting/new-version/composables/inlichting-validation.composable'

export enum RechterlijkeInstantie {
  HogeRaadVoorHandhavingsuitvoering = 'HogeRaadVoorHandhavingsuitvoering',
  RechtbankEersteAanlegBurgerlijk = 'RechtbankEersteAanlegBurgerlijk',
  RechtbankEersteAanlegCorrectioneel = 'RechtbankEersteAanlegCorrectioneel',
  HofVanBeroep = 'HofVanBeroep',
  HofVanCassatie = 'HofVanCassatie'
}

export interface UitspraakRechterlijkeInstantie {
  rechtbank?: RechterlijkeInstantie,
  adviesHogeRaadVoorHandhavingsuitvoering?: AdviesHogeRaadVoorHandhavingsuitvoering,
  rechtbankEersteAanleg?: RechtbankVanEersteAanleg,
  hofVanBeroep?: HofVanBeroep
  hofVanCassatie?: RestHofVanCassatieUitspraak
}

export default defineComponent({
  name: 'proces-verbaal-edit',
  components: { PvUitsprakenEdit, CollectionItem },
  props: {
    instrument: Object as PropType<ProcesVerbaal>,
    procedureIndex: Number,
    instrumentIndex: Number,
    validationErrors: Map<string, string>,
    modDisabled: Boolean
  },
  setup (props, { emit }) {
    const { makeUnique } = useHandhavingsinstrument()
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()
    const { hasError } = useInlichtingValidation(props.validationErrors)

    const pvEdit = ref(props.instrument as ProcesVerbaal)
    const showVervolging = computed(() => pvEdit.value.vervolging?.vorderingInstantie != null)

    const handleUitsprakenChanged = (uitspraken: UitsprakenRechterlijkeInstantie) => {
      console.log('pv changed', uitspraken)
      pvEdit.value.vervolging.uitsprakenRechterlijkeInstantie = uitspraken
    }

    const flattenUitspraken = (): UitspraakRechterlijkeInstantie[] => {
      const uitspraken = pvEdit.value.vervolging?.uitsprakenRechterlijkeInstantie
      if (uitspraken == null) {
        return []
      }
      const uitsprakenMapped: UitspraakRechterlijkeInstantie[] = []
      if (uitspraken.adviesHogeRaadVoorHandhavingsuitvoering != null) {
        uitsprakenMapped.push({ rechtbank: RechterlijkeInstantie.HogeRaadVoorHandhavingsuitvoering, adviesHogeRaadVoorHandhavingsuitvoering: uitspraken.adviesHogeRaadVoorHandhavingsuitvoering })
      }
      uitsprakenMapped.push(...uitspraken.rechtbankenVanEersteAanleg.map(uitspraak => (
        {
          rechtbank: uitspraak.typeRechtbank === RechtbankVanEersteAanlegTypeRechtbank.BURGERLIJK
            ? RechterlijkeInstantie.RechtbankEersteAanlegBurgerlijk
            : RechterlijkeInstantie.RechtbankEersteAanlegCorrectioneel,
          rechtbankEersteAanleg: uitspraak
        }
      )))
      uitsprakenMapped.push(...uitspraken.hovenVanBeroep.map(uitspraak => ({ rechtbank: RechterlijkeInstantie.HofVanBeroep, hofVanBeroep: uitspraak })))
      uitsprakenMapped.push(...uitspraken.hofVanCassatieUitspraken?.map(uitspraak => ({ rechtbank: RechterlijkeInstantie.HofVanCassatie, hofVanCassatie: uitspraak })))
      return uitsprakenMapped
    }

    const handleBeslissingParket = (beslissingParket: ProcesVerbaalBeslissingParket) => {
      switch (beslissingParket) {
        case ProcesVerbaalBeslissingParket.GEEN_GERECHTELIJKE_VERVOLGING:
          pvEdit.value.vervolging = null
          pvEdit.value.seponering = null
          break
        case ProcesVerbaalBeslissingParket.SEPONERING:
          pvEdit.value.vervolging = null
          pvEdit.value.seponering = { reden: '' } as Seponering
          break
        case ProcesVerbaalBeslissingParket.VERVOLGING:
          pvEdit.value.vervolging = { } as Vervolging
          pvEdit.value.seponering = null
          break
      }
    }

    watch(() => pvEdit.value, (newValue) => {
      emit('instrument-changed', { index: props.instrumentIndex, type: HandhavingsinstrumentType.ProcesVerbaal, value: newValue })
    })

    return {
      pvEdit,
      options,
      showVervolging,
      hasError,
      getLabel,
      makeUnique,
      handleUitsprakenChanged,
      handleBeslissingParket,
      flattenUitspraken,
    }
  }
})
