import { defineComponent, PropType, ref, watch } from 'vue'
import useHandhavingsinstrument
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/composable/handhavingsinstrument.composable'
import { InbreukBestuurlijkeSanctie } from '@/infrastructure/bff-client/bff-client'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import { HandhavingsinstrumentType } from '@/components/vip-inlichting/new-version/components/inlichtingen/stedenbouwkundige-overtreding/edit/procedure/procedure-edit'
import useInlichtingValidation
  from '@/components/vip-inlichting/new-version/composables/inlichting-validation.composable'

export default defineComponent({
  name: 'bestuurlijke-sanctie-edit',
  props: {
    instrument: String as PropType<InbreukBestuurlijkeSanctie>,
    procedureIndex: Number,
    instrumentIndex: Number,
    modDisabled: Boolean,
    validationErrors: Map<string, string>
  },
  setup (props, { emit }) {
    const { makeUnique } = useHandhavingsinstrument()
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()
    const { hasError } = useInlichtingValidation(props.validationErrors)

    const sanctieEdit = ref(props.instrument)

    watch(() => sanctieEdit.value, (newValue) => {
      emit('instrument-changed', { index: props.instrumentIndex, type: HandhavingsinstrumentType.BestuurlijkeSanctie, value: newValue })
    })

    return {
      sanctieEdit,
      options,
      hasError,
      getLabel,
      makeUnique
    }
  }
})
